/** Generated js for domain-specific scripting
 * by common\modules\domains\helpers\DomainJavascriptWriter
 */

document.addEventListener('click', function (event) {
  var isExpandButton = event.target.matches('expand-button') || event.target.closest('.expand-button') != null;
  var expanderParent = event.target.closest('.expander');
  if (isExpandButton && expanderParent !== null) {
    event.preventDefault();
    toggleExpander(expanderParent, true);
  }
});
var toggleExpander = function toggleExpander(expanderElement, toggle) {
  if (toggle) {
    expanderElement.classList.toggle('expand');
  }
  var maxHeight = "999em";
  if (!expanderElement.classList.contains('expand')) {
    var _expanderElement$data;
    maxHeight = (_expanderElement$data = expanderElement.dataset.minimalHeight) !== null && _expanderElement$data !== void 0 ? _expanderElement$data : "50px";
  }
  var contentElement = expanderElement.querySelector('.expander-content');
  contentElement.style.maxHeight = maxHeight;
};
/*var expanders = document.querySelectorAll('.expander');
var _iterator = _createForOfIteratorHelper(expanders),
  _step;
try {
  for (_iterator.s(); !(_step = _iterator.n()).done;) {
    var expander = _step.value;
    toggleExpander(expander, false);
  }
} catch (err) {
  _iterator.e(err);
} finally {
  _iterator.f();
}*/